import { atom, atomFamily, selector, selectorFamily } from "recoil";

import api from "../lib/api";
import featureFlags from "../feature-flag.json";
import logUserActivity from "../common/user-activity-logging";

// TODO: split this into separate files

// Terms and services
export const disclaimerOpenAtom = atom({
  key: "disclaimerOpen",
  default: false,
});

export const disclaimerLoadingAtom = atom({
  key: "disclaimerLoading",
  default: true,
});

export const agreedToTermsAtom = atom({
  key: "agreedToTerms",
  default: !!sessionStorage.getItem("agreedToTerms"),
});

export const appInfoState = selector({
  key: "appInfo",
  get: async () => {
    const response = await api.get("app-info");
    return response.data;
  },
});

// Chemistry
export const chemicalBoreholesState = selector({
  key: "chemicalBoreholes",
  get: async ({ get }) => {
    const response = await api.get("chemistry/boreholes");
    // TODO: check this
    if (response.data.error) {
      throw response.data.error;
    }
    return response.data;
  },
});

export const chemistryDatapointThreads = selector({
  key: "chemistryThreads",
  get: async ({ get }) => {
    const datapoint = get(selectedDataPointFamily("chemistry"));
    if (!datapoint || !datapoint.threads) {
      return [];
    }
    return datapoint.threads;
  },
});

export const determinantListState = selectorFamily({
  key: "determinants",
  get: (boreholes) => async () => {
    if (!boreholes) {
      return [];
    }

    const response = await api.get("chemistry/determinants", {
      params: { boreholes },
    });
    if (response.data.error) {
      throw response.data.error;
    }
    return response.data;
  },
});

export const selectedDeterminantSelector = selector({
  key: "selectedDeterminant",
  get: ({ get }) => {
    return get(selectedDeterminantState);
  },
  set: ({ get, set }, newValue) => {
    set(selectedDataPointFamily("chemistry"), null);
    set(selectedDeterminantState, newValue);

    logUserActivity({
      action: "DETERMINANT_SELECTED",
      component: "chemistry",
      data: newValue,
    });
  },
});

export const chemistryDataState = selector({
  key: "chemistry",
  get: async ({ get }) => {
    const determinant = get(selectedDeterminantState);
    const selectedBoreholes = get(selectedBoreholesChemistryState);
    const nullValues = get(chemistryNullValuesState);
    const type = get(chemistryDataTypeState);

    if (!determinant || !selectedBoreholes.length) {
      return [];
    }

    const requests = selectedBoreholes.map((boreholeId) =>
      api.get("chemistry/series", {
        params: {
          determinant,
          boreholeId,
          type,
          nullValues,
        },
      })
    );

    const responseArray = await Promise.allSettled(requests);

    const formattedResponse = responseArray.map((result, index) => {
      return {
        name: selectedBoreholes[index],
        data: result?.value?.data?.series,
        legend: result?.value?.data?.legend,
      };
    });

    return formattedResponse.filter((item) => item?.data?.length);
  },
});

export const selectedBoreholesChemistryState = atom({
  key: "selectedBoreholesChemistryAtom",
  default: [],
});

export const selectedBoreholesChemistrySelector = selector({
  key: "selectedBoreholesChemistry",
  get: ({ get }) => {
    return get(selectedBoreholesChemistryState);
  },
  set: ({ get, set }, newValue) => {
    set(selectedDataPointFamily("chemistry"), null);
    set(
      visibleSeriesState("chemistry"),
      newValue.map((borehole) => ({ name: borehole, visible: true }))
    );
    set(selectedBoreholesChemistryState, newValue);

    logUserActivity({
      action: "BOREHOLE_SELECTED",
      component: "chemistry",
      data: newValue,
    });
  },
});

export const selectedDeterminantState = atom({
  key: "selectedDeterminantAtom",
  default: null,
});

export const chemistryNullValuesState = atom({
  key: "chemistryNullValuesState",
  default: "zero",
});

export const chemistryDataTypeState = atom({
  key: "chemistryDataTypeState",
  default: "all",
});

// CC Chemistry
export const ccchemicalBoreholesState = selector({
  key: "ccchemicalBoreholes",
  get: async ({ get }) => {
    const response = await api.get("chemconnect/boreholes");
    // TODO: check this
    if (response.data.error) {
      throw response.data.error;
    }
    return response.data;
  },
});

export const ccchemistryDatapointThreads = selector({
  key: "ccchemistryThreads",
  get: async ({ get }) => {
    const datapoint = get(selectedDataPointFamily("ccchemistry"));
    if (!datapoint || !datapoint.threads) {
      return [];
    }
    return datapoint.threads;
  },
});

export const ccdeterminantListState = selectorFamily({
  key: "ccdeterminants",
  get: (boreholes) => async () => {
    if (!boreholes) {
      return [];
    }

    const response = await api.get("chemconnect/determinants", {
      params: { boreholes },
    });
    if (response.data.error) {
      throw response.data.error;
    }
    return response.data;
  },
});

export const ccselectedDeterminantSelector = selector({
  key: "ccselectedDeterminant",
  get: ({ get }) => {
    return get(ccselectedDeterminantState);
  },
  set: ({ get, set }, newValue) => {
    set(selectedDataPointFamily("ccchemistry"), null);
    set(ccselectedDeterminantState, newValue);

    logUserActivity({
      action: "DETERMINANT_SELECTED",
      component: "chemconnect",
      data: newValue,
    });
  },
});

export const ccchemistryDataState = selector({
  key: "chemconnect",
  get: async ({ get }) => {
    const determinant = get(ccselectedDeterminantState);
    const selectedBoreholes = get(ccselectedBoreholesChemistryState);
    const nullValues = get(ccchemistryNullValuesState);
    const type = get(ccchemistryDataTypeState);

    if (!determinant || !selectedBoreholes.length) {
      return [];
    }

    const requests = selectedBoreholes.map((boreholeId) =>
      api.get("chemconnect/series", {
        params: {
          determinant,
          boreholeId,
          type,
          nullValues,
        },
      })
    );

    const responseArray = await Promise.allSettled(requests);

    const formattedResponse = responseArray.map((result, index) => {
      return {
        name: selectedBoreholes[index],
        data: result?.value?.data?.series,
        legend: result?.value?.data?.legend,
      };
    });

    return formattedResponse.filter((item) => item?.data?.length);
  },
});

export const ccselectedBoreholesChemistryState = atom({
  key: "ccselectedBoreholesChemistryAtom",
  default: [],
});

export const ccselectedBoreholesChemistrySelector = selector({
  key: "ccselectedBoreholesChemistry",
  get: ({ get }) => {
    return get(ccselectedBoreholesChemistryState);
  },
  set: ({ get, set }, newValue) => {
    set(selectedDataPointFamily("ccchemistry"), null);
    set(
      visibleSeriesState("ccchemistry"),
      newValue.map((borehole) => ({ name: borehole, visible: true }))
    );
    set(ccselectedBoreholesChemistryState, newValue);

    logUserActivity({
      action: "BOREHOLE_SELECTED",
      component: "chemconnect",
      data: newValue,
    });
  },
});

export const ccselectedDeterminantState = atom({
  key: "ccselectedDeterminantAtom",
  default: null,
});

export const ccchemistryNullValuesState = atom({
  key: "ccchemistryNullValuesState",
  default: "zero",
});

export const ccchemistryDataTypeState = atom({
  key: "ccchemistryDataTypeState",
  default: "all",
});

// Water levels
export const waterLevelsPipesState = selector({
  key: "waterLevelPipes",
  get: async ({ get }) => {
    const response = await api.get("waterlevel/pipes");
    // TODO: check this
    if (response.data.error) {
      throw response.data.error;
    }
    return response.data;
  },
});


export const waterLevelsBoreholesState = selector({
  key: "waterLevelsBoreholes",
  get: async ({ get }) => {
    const response = await api.get("waterlevel/boreholes");
    // TODO: check this
    if (response.data.error) {
      throw response.data.error;
    }
    return response.data;
  },
});


export const selectedBoreholesWaterLevelsState = atom({
  key: "selectedBoreholesWaterLevels",
  default: [],
});

export const selectedBoreholesWaterlevelsSelector = selector({
  key: "selectedBoreholesWaterlevelsSelector",
  get: ({ get }) => {
    return get(selectedBoreholesWaterLevelsState);
  },
  set: ({ get, set }, newValue) => {
    set(selectedDataPointFamily("waterlevels"), null);

    const visibleSeries = newValue.map((borehole) => ({
      name: borehole.borehole_pipe_id,
      visible: true,
    }));

    // add extra series for the rainfall data
    if (newValue.length === 1) {
      visibleSeries.push({ name: "Rainfall", visible: true });
    }

    set(visibleSeriesState("waterlevels"), visibleSeries);

    set(selectedBoreholesWaterLevelsState, newValue);

    logUserActivity({
      action: "BOREHOLE_SELECTED",
      component: "waterlevel",
      data: newValue,
    });
  },
});

export const waterLevelsDataType = atom({
  key: "waterLevelsDataType",
  default: "all",
});

export const waterLevelsDataTypeSelector = selector({
  key: "waterLevelsDataTypeSelector",
  get: ({ get }) => {
    return get(waterLevelsDataType);
  },

  set: ({ set }, newValue) => {
    if (newValue === "all") {
      set(selectedDataPointFamily("waterlevels"), null);
    }
    set(waterLevelsDataType, newValue);
  },
});

export const waterLevelsUnits = atom({
  key: "waterLevelsUnits",
  default: "maod",
});

export const waterLevelsUnitsSelector = selector({
  key: "waterLevelsUnitsSelector",
  get: ({ get }) => {
    return get(waterLevelsUnits);
  },

  set: ({ set }, newValue) => {
    if (newValue === "mbgl") {
      set(waterLevelsGraphReversed, true);
    }
    set(waterLevelsUnits, newValue);
  },
});

export const waterLevelsGraphReversed = atom({
  key: "waterLevelsGraphReversed",
  default: false,
});

export const waterLevelsLocationData = selector({
  key: "waterLevelsLocationData",
  get: async ({ get }) => {
    const boreholes = get(selectedBoreholesWaterlevelsSelector);
    if (!boreholes) {
      return [];
    }
    const promises = Promise.all(
      boreholes.map((borehole) => api.get(`location/${borehole.borehole_pipe_id}`))
    );
    const responses = await promises;
    return responses.map((response) => response.data);
  },
});

export const allWaterLevelsLocationsHaveNationalGridElevation = selector({
  key: "allWaterLevelsLocationsHaveNationalGridElevation",
  get: ({ get }) => {
    const locationData = get(waterLevelsLocationData);
    let res = !locationData.find(
      (item) => item.national_grid_elevation === null
    );
    return res;
  },
});

export const waterLevelsData = selector({
  key: "waterLevelsData",
  get: async ({ get }) => {
    const selectedBoreholes = get(selectedBoreholesWaterlevelsSelector);
    const type = get(waterLevelsDataType);
    const units = get(waterLevelsUnitsSelector);

    if (!selectedBoreholes) {
      return [];
    }

    const promises = Promise.all(
      selectedBoreholes.map((borehole) =>
        api.get(
          `waterlevel/${type}/series/${encodeURIComponent(
            borehole.borehole_pipe_id
          )}`,
          { params: { pipeType: borehole.pipe_type, units } }
        )
      )
    );
    const responses = await promises;

    return responses.map((item) => item.data);
  },
});

export const waterLevelsSeries = selector({
  key: "waterLevelsSeries",
  get: ({ get }) => {
    const data = get(waterLevelsData);
    const selectedBoreholes = get(selectedBoreholesWaterlevelsSelector);

    if (!data) {
      return null;
    }

    let seriesData = data.map((item, index) => ({
      name: selectedBoreholes[index].borehole_pipe_id,
      data: item.series,
      index,
    }));

    if (featureFlags.rainfall) {
      const rainfallData = get(nearestRainfallStationData);
      // TODO: maybe make this conversion to X Y in API
      if (selectedBoreholes.length === 1 && rainfallData?.length) {
        seriesData = [
          ...seriesData,
          {
            data: rainfallData.map((item) => ({
              y: item.value,
              x: item.date,
            })),
            name: "Rainfall",
            index: 1,
          },
        ];
      }
    }

    return seriesData;
  },
});

export const nearestRainfallStation = selector({
  key: "nearestStation",
  get: async ({ get }) => {
    const selected = get(selectedBoreholesWaterlevelsSelector);
    if (selected.length !== 1) {
      return null;
    }
    try {
      const { data: stations } = await api.get(
        "/rainfall/nearby_stations/" + selected[0].borehole_pipe_id.split('#')[0]
      );
      return stations[0];
    } catch (e) {
      return null;
    }
  },
});

export const nearestRainfallStationData = selector({
  key: "nearestStationRainfall",
  get: async ({ get }) => {
    const nearestStation = get(nearestRainfallStation);
    const location = get(waterLevelsLocationData);
    if (!nearestStation || !location) {
      return null;
    }

    const { data: readings } = await api.get(
      "/rainfall/station/" +
        nearestStation.stationReference +
        "/readings/dailyTotal"
    );
    return readings;
  },
});

export const waterLevelsGuides = selector({
  key: "waterLevelsGuides",
  get: ({ get }) => {
    const data = get(waterLevelsData);
    if (data.length === 1) {
      return data[0].guides;
    }
    return null;
  },
});

// TODO: delete this
export const waterLevelsMin = selector({
  key: "waterLevelsMin",
  get: ({ get }) => {
    const guides = get(waterLevelsGuides);
    if (!guides) {
      return null;
    }
    return Math.min(
      ...[
        guides?.highest,
        guides?.screenBot,
        guides?.lowest,
        guides?.screenTop,
        guides?.groundLevel,
      ].filter(Number.isFinite)
    );
  },
});

export const waterLevelsMax = selector({
  key: "waterLevelsMax",
  get: ({ get }) => {
    const guides = get(waterLevelsGuides);
    if (!guides) {
      return undefined;
    }

    // TODO: check this dynamically by looping
    return Math.max(
      ...[
        guides?.highest,
        guides?.screenBot,
        guides?.lowest,
        guides?.screenTop,
        guides?.groundLevel,
      ].filter(Number.isFinite)
    );
  },
});

export const selectedDataPointFamily = atomFamily({
  key: "selectedDataPoint",
  default: null,
});

// TODO: maybe find a way to not hardcode this logic for every graph.
// it works for now but might become annoying later.
export const selectedWaterLevelsDataPointMeta = selector({
  key: "selectedDataPointMeta",
  get: ({ get }) => {
    const dataPoint = get(selectedDataPointFamily("waterlevels"));
    const data = get(waterLevelsData);
    const selectedBoreholes = get(selectedBoreholesWaterlevelsSelector);

    if (!dataPoint || !selectedBoreholes.length) {
      return null;
    }

    return {
      id: data[dataPoint.seriesIndex].series[dataPoint.dataPointIndex].id,
      boreholeId: selectedBoreholes[dataPoint.seriesIndex].borehole_pipe_id.split("#")[0],
      boreholePipeId: selectedBoreholes[dataPoint.seriesIndex].borehole_pipe_id,
    };
  },
});

export const waterLevelsDataPointThreads = selector({
  key: "waterLevelsThreads",
  get: ({ get }) => {
    const datapoint = get(selectedDataPointFamily("waterlevels"));
    if (!datapoint || !datapoint.threads) {
      return [];
    }
    return datapoint.threads;
  },
});

export const dateWindowState = atomFamily({
  key: "graphZoomX",
  default: null,
});

export const valueRangeState = atomFamily({
  key: "graphZoomY",
  default: null,
});

export const commentsState = selectorFamily({
  key: "comments",
  get: (threadId) => async () => {
    if (!threadId) {
      return [];
    }

    // @ts-ignore
    const response = await api.get("comments/" + threadId);
    if (response.data.error) {
      throw response.data.error;
    }
    return response.data;
  },
});

export const visibleSeriesState = atomFamily({
  key: "visibleSeries",
  default: [],
});

// Admin

// Users
export const usersData = selector({
  key: "Users",
  get: async () => {
    const response = await api.get("user");
    return response.data;
  },
});

export const userGroupsData = selector({
  key: "UserGroups",
  get: async () => {
    const response = await api.get("user/groups");
    return response.data;
  },
});
